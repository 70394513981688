<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="detail_dialog" :top="'2vh'" :fullscreen="isExpand"
    :show-close="false" :before-close="handleClose">
    <template slot="title">
      <div class="title-box">
        <span>商机/询价详情</span>
        <span class="icon-box">
          <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand">
          <i class="el-icon-close" @click="handleClose"></i>
        </span>
      </div>
    </template>
    <div class="content">
      <div class="content__main">
        <div class="content__main-flag">
          <img :src="flagSvg">
        </div>
        <div class="content__main-header">
          <div class="content__main-header-order">询单号：{{ detail.code }}</div>
          <div class="content__main-header-name">
            {{ handleEncrypt(detail.inquirePriceParty, -2) }}_{{ detail.businessTypeDesc }}_{{ detail.code }}
            <span :class="['c_tag', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              {{ detail.inquireTenantStatusDesc }}
            </span>
            <span v-if="detail.inquireTenantStatus === 'CLOSED'"
              :class="['c_reason', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              <i class="el-icon-warning-outline"></i>关单原因：{{ detail.closeReason }}
            </span>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="询单信息" name="order">
          <component :is="orderComponent" class="pane" :info="detail" :isEncryptable="isEncryptable"></component>
        </el-tab-pane>
        <el-tab-pane label="报价信息" name="price">
          <component :is="priceComponent" class="pane" :id="id"></component>
        </el-tab-pane>
        <el-tab-pane label="履约信息" name="contract">
          <Contract class="pane"></Contract>
        </el-tab-pane>
        <el-tab-pane label="日志动态" name="log">
          <Log class="pane" :id="id"></Log>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关闭</el-button>
      <slot name="button"></slot>
    </span>
  </el-dialog>
</template>
<script>
import MaintainOrder from './maintain/order.vue';
import MaintainPrice from './maintain/price.vue';
import SparepartOrder from './sparepart/order.vue';
import SparepartPrice from './sparepart/price.vue';
import Contract from './contract.vue';
import Log from './log.vue';
import * as Moment from 'dayjs';
import { inquirePriceDetail } from '@/api/inquiryForm.js';

const componentMap = {
  MAINTAIN: {
    orderComponent: MaintainOrder,
    priceComponent: MaintainPrice,
  },
  SPARE_PART: {
    orderComponent: SparepartOrder,
    priceComponent: SparepartPrice,
  },
};

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    isEncryptable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MaintainOrder,
    MaintainPrice,
    Contract,
    Log,
  },
  computed: {
    orderComponent () {
      return componentMap[this.type].orderComponent;
    },
    priceComponent () {
      return componentMap[this.type].priceComponent;
    },
  },
  data () {
    return {
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      flagSvg: require('@/assets/document_checked.svg'),
      activeName: 'order',
      detail: {},
    };
  },
  methods: {
    handleEncrypt (v) {
      let ret = v;

      if (v && this.isEncryptable) {
        ret = `${v.slice(0, -2).replace(/./g, '*')}公司`;
      }

      return ret || '--';
    },
    handleClose () {
      this.$emit('update:dialogVisible', false);
    },
    formatDate (text) {
      return text ? Moment(text).format('YYYYMMDD') : '';
    },
    getDetail () {
      inquirePriceDetail({
        id: this.id,
      }).then(({ body }) => {
        this.detail = body || {};
      });
    },
  },
  mounted () {
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .content__main {
    display: flex;
    align-items: center;

    .content__main-header {
      margin-left: 12px;

      .content__main-header-order {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }

      .content__main-header-name {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .c_reason {
          font-size: 12px;
          color: #FAB007;
          margin-left: 8px;
        }

        .wait_task_order {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .wait_quote_price {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .quoted {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .negotiating {
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .entered {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .rejected {
          background-color: #FEE9E5;
          color: #FF5959;
        }

        .closed {
          background-color: #F2F4F7;
          color: #1F2733;
        }
      }
    }
  }

  .pane {
    height: calc(90vh - 220px);
    overflow: auto;
  }
}
</style>
